import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Footer extends Component {
  render() {
    return (
      <Container className="pt-3 pb-2 text-center">Powered by You</Container>
    );
  }
}
