import { all, fork } from 'redux-saga/effects';

import auth from './auth';
import editor from './editor';
import event from './event';
import forum from './forum';
import list from './list';
import org from './org';
import personnel from './personnel';
import privilege from './privilege';
import role from './role';
import toast from './toast';
import user from './user';

export default function* saga() {
  yield all(
    [
      auth,
      editor,
      event,
      forum,
      list,
      org,
      personnel,
      privilege,
      role,
      toast,
      user,
    ].map(fork)
  );
}
