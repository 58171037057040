import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import helper from 'utils/saga';
import request from 'utils/request';
import { actions, types } from 'redux/reducers/event';
import { getDateEvents } from 'redux/selectors/event';

// snake_cased variables here come from RFC 6749
function* requestEventsWorker() {
  try {
    const endpoint = {
      url: '/colony/events',
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'collection', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get events!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestDateEventsWorker({ date }) {
  try {
    const events = yield select(getDateEvents);

    const endpoint = {
      url: `/colony/events/date/${date}`,
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      events[date] = data;

      console.log(events);

      yield put(actions.requestSuccess({ reqType: 'date', data: events }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get events!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestTodayEventsWorker({ date }) {
  try {
    const endpoint = {
      url: `/colony/events/date/${date}`,
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'today', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get events!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestTomorrowEventsWorker({ date }) {
  try {
    const endpoint = {
      url: `/colony/events/date/${date}`,
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'tomorrow', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get events!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* addEventWorker({
  event: {
    _key,
    date,
    description,
    startDate,
    endDate,
    eventType,
    location,
    org,
    time,
    title,
    startTime,
    endTime,
    update,
    zone,
  },
}) {
  try {
    const endpoint = {
      url: !_key ? `/colony/event` : `/colony/event/${_key}`,
      method: !_key ? 'POST' : 'PUT',
    };

    const data = {
      date,
      description,
      startDate,
      endDate,
      eventType,
      location,
      org,
      time,
      title,
      startTime,
      endTime,
      zone,
    };

    const result = yield call(request.execute, { endpoint, data });

    if (result.success) {
      yield put(actions.requestEvents({ org: 'muns' }));
      yield call(helper.toast, {
        title: !update ? 'Event Created' : `Event Updated`,
        message: !update
          ? `${eventType} ${title} successfully added!`
          : `${eventType} ${title} successfully updated!`,
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to add Event!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* deleteEventWorker({ _key }) {
  try {
    const endpoint = {
      url: `/colony/event/${_key}`,
      method: 'DELETE',
    };

    const result = yield call(request.execute, { endpoint });

    if (result.success) {
      yield put(actions.requestEvents({ org: 'muns' }));
      yield call(helper.toast, {
        title: `Event Deleted`,
        message: `Event ID ${_key} successfully deleted!`,
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to delete Event!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestEventTypesWorker() {
  try {
    const endpoint = {
      url: '/colony/event/types',
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'eventTypes', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get event types!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestEventTypeWorker({ eventType: { _key, name } }) {
  try {
    const endpoint = {
      url: _key ? `/colony/event/type/${_key}` : `/colony/event/type/${name}`,
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'eventType', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get event type!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* addEventTypeWorker({
  eventType: { _key, bgColor, location, name, org, textColor, update, zone },
}) {
  try {
    const endpoint = {
      url: !update ? `/colony/event/type` : `/colony/event/type/${_key}`,
      method: !update ? 'POST' : 'PUT',
    };

    const data = {
      defaults: {
        location,
        org,
        zone,
      },
      name,
      style: {
        bgColor,
        textColor,
      },
    };

    const result = yield call(request.execute, { endpoint, data });

    if (result.success) {
      yield put(actions.requestEventTypes({ org: 'muns' }));
      yield call(helper.toast, {
        title: !update ? 'Event Type Created' : `Event Type Updated`,
        message: !update
          ? `Event Type ${name} successfully added!`
          : `Event Type ${name} successfully updated!`,
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to add Event Type!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* deleteEventTypeWorker({ _key }) {
  try {
    const endpoint = {
      url: `/colony/event/type/${_key}`,
      method: 'DELETE',
    };

    const result = yield call(request.execute, { endpoint });

    if (result.success) {
      yield put(actions.requestEventTypes({ org: 'muns' }));
      yield call(helper.toast, {
        title: `Event Type Deleted`,
        message: `Event Type ID ${_key} successfully deleted!`,
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to delete Event Type!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* requestEventsWatcher() {
  yield takeLatest(types.REQUEST_EVENTS, requestEventsWorker);
}

function* requestDateEventsWatcher() {
  yield takeLatest(types.REQUEST_DATE_EVENTS, requestDateEventsWorker);
}

function* requestTodayEventsWatcher() {
  yield takeLatest(types.REQUEST_TODAY_EVENTS, requestTodayEventsWorker);
}

function* requestTomorrowEventsWatcher() {
  yield takeLatest(types.REQUEST_TOMORROW_EVENTS, requestTomorrowEventsWorker);
}

function* addEventWatcher() {
  yield takeLatest(types.ADD_EVENT, addEventWorker);
}

function* deleteEventWatcher() {
  yield takeLatest(types.DELETE_EVENT, deleteEventWorker);
}

function* requestEventTypesWatcher() {
  yield takeLatest(types.REQUEST_EVENT_TYPES, requestEventTypesWorker);
}

function* requestEventTypeWatcher() {
  yield takeLatest(types.REQUEST_EVENT_TYPE, requestEventTypeWorker);
}

function* addEventTypeWatcher() {
  yield takeLatest(types.ADD_EVENT_TYPE, addEventTypeWorker);
}

function* deleteEventTypeWatcher() {
  yield takeLatest(types.DELETE_EVENT_TYPE, deleteEventTypeWorker);
}

export const workers = {
  requestEventsWorker,
  requestDateEventsWorker,
  requestTodayEventsWorker,
  requestTomorrowEventsWorker,
  addEventWorker,
  deleteEventWorker,
  requestEventTypesWorker,
  requestEventTypeWorker,
  addEventTypeWorker,
  deleteEventTypeWorker,
};

export const watchers = {
  requestEventsWatcher,
  requestDateEventsWatcher,
  requestTodayEventsWatcher,
  requestTomorrowEventsWatcher,
  addEventWatcher,
  deleteEventWatcher,
  requestEventTypesWatcher,
  requestEventTypeWatcher,
  addEventTypeWatcher,
  deleteEventTypeWatcher,
};

export default function* saga() {
  yield all(Object.values(watchers).map((watcher) => watcher()));
}
