import { createSelector } from 'reselect';

export const getEventsSelector = (state) => state.event;

export const getEvents = createSelector(
  getEventsSelector,
  (event) => event.collection
);

export const getDateEvents = createSelector(
  getEventsSelector,
  (event) => event.date
);

export const getTodayEvents = createSelector(
  getEventsSelector,
  (event) => event.today
);

export const getTomorrowEvents = createSelector(
  getEventsSelector,
  (event) => event.tomorrow
);

export const getEventType = createSelector(
  getEventsSelector,
  (event) => event.eventType
);

export const getEventTypes = createSelector(
  getEventsSelector,
  (event) => event.eventTypes
);

export const getUIEvents = createSelector(
  getEventsSelector,
  (event) => event.uiEvents
);

export const getUIEventTypesList = createSelector(
  getEventsSelector,
  (event) => event.uiEvents?.eventTypesList
);

export const getError = createSelector(
  getEventsSelector,
  (event) => event.error
);
