import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import helper from 'utils/saga';
import { actions, types } from 'redux/reducers/list';

// snake_cased variables here come from RFC 6749
function* requestListWorker({ list }) {
  try {
    const endpoint = {
      url: `/colony/list/${list}`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListSuccess({ list, data }));
      console.log(list);
      console.log(data);
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get list!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestBuildingListWorker() {
  try {
    const endpoint = {
      url: `/colony/list/building`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListSuccess({ list: 'building', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get building list!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestShiftListWorker() {
  try {
    const endpoint = {
      url: `/colony/list/shift`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListSuccess({ list: 'shift', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get shift list!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestStatusListWorker() {
  try {
    const endpoint = {
      url: `/colony/list/status`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListSuccess({ list: 'status', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get status list!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestZoneListWorker() {
  try {
    const endpoint = {
      url: `/colony/list/zone`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListSuccess({ list: 'zone', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get zone list!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestListsWorker() {
  try {
    const endpoint = {
      url: `/colony/lists`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestListsSuccess({ data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get lists!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* requestListWatcher() {
  yield takeLatest(types.REQUEST_LIST, requestListWorker);
}

function* requestBuildingListWatcher() {
  yield takeLatest(types.REQUEST_BUILDING_LIST, requestBuildingListWorker);
}

function* requestShiftListWatcher() {
  yield takeLatest(types.REQUEST_SHIFT_LIST, requestShiftListWorker);
}

function* requestStatusListWatcher() {
  yield takeLatest(types.REQUEST_STATUS_LIST, requestStatusListWorker);
}

function* requestZoneListWatcher() {
  yield takeLatest(types.REQUEST_ZONE_LIST, requestZoneListWorker);
}

function* requestListsWatcher() {
  yield takeLatest(types.REQUEST_LISTS, requestListsWorker);
}

export const workers = {
  requestListWorker,
  requestBuildingListWorker,
  requestShiftListWorker,
  requestStatusListWorker,
  requestZoneListWorker,
  requestListsWorker
};

export const watchers = {
  requestListWatcher,
  requestBuildingListWatcher,
  requestShiftListWatcher,
  requestStatusListWatcher,
  requestZoneListWatcher,
  requestListsWatcher
};

export default function* saga() {
  yield all(Object.values(watchers).map(watcher => watcher()));
}
