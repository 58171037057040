import { all, call, put, takeLatest } from 'redux-saga/effects';
import helper from 'utils/saga';
import request from 'utils/request';
import { actions, types } from 'redux/reducers/personnel';

// snake_cased variables here come from RFC 6749
function* requestPersonnelWorker({
  personnel: { org, flight, section, only },
}) {
  try {
    let url = '/colony/personnel';

    if (org) {
      url += `/${org}`;
    }
    if (flight) {
      url += `/${flight}`;
    }
    if (section) {
      url += `/${section}`;
    }
    if (!section && only) {
      url += `/only`;
    }
    const endpoint = {
      url,
      method: 'GET',
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data },
      } = result;

      yield put(actions.requestSuccess({ reqType: 'collection', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get personnel!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* addPersonnelWorker({
  details: {
    _key,
    org,
    flight,
    section,
    rank,
    lastName,
    firstName,
    building,
    room,
    phone,
    shift,
    status,
    zone,
    update,
  },
}) {
  try {
    const endpoint = {
      url: `/colony/person`,
      method: !update ? 'POST' : 'PUT',
    };

    const data = {
      _key,
      org,
      flight,
      section,
      rank,
      lastName,
      firstName,
      building,
      room,
      phone,
      shift,
      status,
      zone,
    };

    const result = yield call(request.execute, { endpoint, data });

    if (result.success) {
      yield put(actions.requestPersonnel({ org: 'muns' }));
      yield call(helper.toast, {
        title: !update ? 'Personnel Created' : `Personnel Updated`,
        message: !update
          ? `${rank} ${lastName} successfully added!`
          : `${rank} ${lastName} successfully updated!`,
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to add Personnel!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* requestPersonnelWatcher() {
  yield takeLatest(types.REQUEST_PERSONNEL, requestPersonnelWorker);
}

function* addPersonnelWatcher() {
  yield takeLatest(types.ADD_PERSONNEL, addPersonnelWorker);
}

export const workers = {
  requestPersonnelWorker,
  addPersonnelWorker,
};

export const watchers = {
  requestPersonnelWatcher,
  addPersonnelWatcher,
};

export default function* saga() {
  yield all(Object.values(watchers).map((watcher) => watcher()));
}
