import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAlignLeft,
  faAtom,
  faBan,
  faBook,
  faBullhorn,
  faCalendarAlt,
  faCheck,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronCircleRight,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCode,
  faEdit,
  faEquals,
  faHeadSideMask,
  faHeart,
  faInfo,
  faInfoCircle,
  faKey,
  faLaptopCode,
  faLightbulb,
  faLock,
  faNewspaper,
  faPhone,
  faQuestion,
  faPlus,
  faRadiation,
  faRadiationAlt,
  faSms,
  faThList,
  faTimesCircle,
  faTrashAlt,
  faUserCircle,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCheckSquare,
  faSquare,
  faHeart as farHeart,
  faSave,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAlignLeft,
  faAtom,
  faBan,
  faBook,
  faBullhorn,
  faCalendarAlt,
  faCheck,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronCircleRight,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCode,
  faEdit,
  faEquals,
  faHeadSideMask,
  faHeart,
  farHeart,
  faInfo,
  faInfoCircle,
  faKey,
  faLaptopCode,
  faLightbulb,
  faLock,
  faPhone,
  faPlus,
  faNewspaper,
  faQuestion,
  faRadiation,
  faRadiationAlt,
  faSave,
  faSms,
  faSquare,
  faThList,
  faTimesCircle,
  faTrashAlt,
  faUserCircle,
  faUserShield
);

export default library;
