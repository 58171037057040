import { buildActions } from 'utils';

export const types = buildActions('personnel', [
  'REQUEST_PERSONNEL',
  'ADD_PERSONNEL',
  'UPDATE_PERSONNEL',
  'DELETE_PERSONNEL',
  'REQUEST_SUCCESS',
  'REQUEST_FAILURE',
  'RESET',
]);

const requestPersonnel = (personnel) => ({
  type: types.REQUEST_PERSONNEL,
  personnel,
});

const addPersonnel = (details) => ({
  type: types.ADD_PERSONNEL,
  details,
});

const updatePersonnel = ({ personnel }) => ({
  type: types.UPDATE_PERSONNEL,
  personnel,
});
const deletePersonnel = ({ personnel }) => ({
  type: types.DELETE_PERSONNEL,
  personnel,
});

const requestSuccess = ({ reqType, data }) => ({
  type: types.REQUEST_SUCCESS,
  reqType,
  data,
});

const requestFailure = (error) => ({
  type: types.REQUEST_FAILURE,
  error,
});

const reset = (reqType) => ({
  type: types.RESET,
  reqType,
});

export const actions = {
  requestPersonnel,
  addPersonnel,
  updatePersonnel,
  deletePersonnel,
  requestSuccess,
  requestFailure,
  reset,
};

export const initialState = {
  error: {},
  collection: [],
  person: {},
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SUCCESS:
      return {
        ...state,
        [action.reqType]: action.data,
      };
    case types.REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.RESET:
      return {
        ...state,
        [action.reqType]: {},
      };
    default:
      return state;
  }
};
