import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { actions as authActions } from 'redux/reducers/auth';
import { getCurrentUser, isLoggedIn } from 'redux/selectors/auth';

export class Header extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { actions } = this.props;
    this.logoutUser = this.logoutUser.bind(this);
    actions.requestCurrentUser();
  }

  logoutUser() {
    const { actions } = this.props;

    actions.logoutUser();
  }

  get userIcon() {
    return <FontAwesomeIcon icon="user-circle" />;
  }

  render() {
    const { loggedIn, user } = this.props;

    return (
      <Container fluid>
        <Navbar bg="dark" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            The 1<FontAwesomeIcon icon="radiation-alt" className="mr-1" />8
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {loggedIn && user ? (
              <Fragment>
                <Nav className="mr-auto">
                  <Nav.Link as={NavLink} to="/">
                    Home
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/organization">
                    Organization
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/personnel">
                    Personnel
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/events">
                    Events
                  </Nav.Link>
                  <NavDropdown
                    title="Recall"
                    id="admin-nav-menu"
                    variant="success"
                  >
                    <NavDropdown.Item as={NavLink} to="/recall/muns">
                      MUNS
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwf">
                      Facility Flight
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwf/mxwfv">
                      VIIDS
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxws">
                      Maintenance Flight
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxws/mxwsw">
                      Maintenance Section
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxws/mxwss">
                      Support Section
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwk">
                      Systems Flight
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwk/mxwka">
                      Control Section
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwk/mxwsk">
                      NARS Section
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwk/mxwkc">
                      Plans &amp; Scheduling Section
                    </NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/recall/muns/mxwk/mxwkd">
                      Training Section
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={NavLink} to="/forum">
                    Forum
                  </Nav.Link>
                </Nav>
                {user?.privileges?.ui_admin ? (
                  <Nav>
                    <NavDropdown
                      title="Administrator"
                      id="admin-nav-menu"
                      variant="success"
                    >
                      <NavDropdown.Item as={NavLink} to="/organization">
                        Organizations
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={NavLink} to="/events">
                        Events
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={NavLink} to="/admin/roles">
                        Roles
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavLink} to="/admin/privileges">
                        Privileges
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavLink} to="/admin/users">
                        Users
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={NavLink} to="/admin/forum">
                        Forum
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : (
                  ''
                )}
                <Nav>
                  <NavDropdown title={user.name} id="user-nav-menu">
                    <NavDropdown.Item href="#action/3.3">
                      Account
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      as={NavLink}
                      to="/login"
                      onClick={this.logoutUser}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Fragment>
            ) : (
              <Fragment>
                <Nav>
                  <Nav.Link as={NavLink} to="/login">
                    Login
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/register">
                    Register
                  </Nav.Link>
                </Nav>
              </Fragment>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
  user: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
