import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component, Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { actions as authActions } from 'redux/reducers/auth';

import Header from './Header';
import Footer from './Footer';
import {
  Events,
  AdminForum,
  Forum,
  ForumTopics,
  ForumTopic,
  Home,
  Login,
  Organization,
  Personnel,
  Privileges,
  Recall,
  Register,
  Roles,
  Users,
  NotFound,
} from 'pages';
import ToastDrawer from './ToastDrawer/ToastDrawer';
import SuspenseFallback from './SuspenseFallback/SuspenseFallback';

export class App extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.initApp();
  }

  render() {
    return (
      <Suspense fallback={<SuspenseFallback />}>
        <Helmet
          defaultTitle="The 108"
          titleTemplate={`%s | ${process.env.REACT_APP_HTML_TITLE}`}
        />
        <Header />
        <ToastDrawer />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin/forum" component={AdminForum} />
          <Route exact path="/admin/privileges" component={Privileges} />
          <Route exact path="/admin/roles" component={Roles} />
          <Route exact path="/admin/users" component={Users} />
          <Route exact path="/forum" component={Forum} />
          <Route exact path="/forum/:name" component={ForumTopics} />
          <Route exact path="/forum/:name/:topic" component={ForumTopic} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/organization" component={Organization} />
          <Route exact path="/personnel" component={Personnel} />
          <Route exact path="/recall" component={Recall} />
          <Route exact path="/recall/:org" component={Recall} />
          <Route exact path="/recall/:org/:flight" component={Recall} />
          <Route
            exact
            path="/recall/:org/:flight/:section"
            component={Recall}
          />
          <Route exact path="/register" component={Register} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...authActions,
    },
    dispatch
  ),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
