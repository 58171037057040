import { buildActions } from 'utils';

export const types = buildActions('org', [
  'REQUEST_ORGS',
  'REQUEST_ORG',
  'REQUEST_ORG_SUCCESS',
  'REQUEST_FLIGHTS',
  'REQUEST_FLIGHT',
  'REQUEST_FLIGHT_SUCCESS',
  'REQUEST_SHOPS',
  'REQUEST_SHOP',
  'REQUEST_SHOP_SUCCESS',
  'ADD_ORG',
  'UPDATE_ORG',
  'UPDATE_FLIGHT',
  'UPDATE_SHOP',
  'DELETE_ORG',
  'DELETE_FLIGHT',
  'DELETE_SHOP',
  'REQUEST_SUCCESS',
  'REQUEST_FAILURE',
  'RESET'
]);

const requestOrgs = level => ({
  type: types.REQUEST_ORGS,
  level
});

const requestOrg = org => ({
  type: types.REQUEST_ORG,
  org
});

const requestOrgSuccess = ({ org }) => ({
  type: types.REQUEST_ORG_SUCCESS,
  org
});

const requestFlights = org => ({
  type: types.REQUEST_FLIGHTS,
  org
});

const requestFlight = (org, flight) => ({
  type: types.REQUEST_FLIGHT,
  org,
  flight
});

const requestFlightSuccess = ({ flight }) => ({
  type: types.REQUEST_FLIGHT_SUCCESS,
  flight
});

const requestSections = (org, flight) => ({
  type: types.REQUEST_SHOPS,
  org,
  flight
});

const requestSection = (org, flight, section) => ({
  type: types.REQUEST_SHOP,
  org,
  flight,
  section
});

const requestSectionSuccess = ({ section }) => ({
  type: types.REQUEST_SHOP_SUCCESS,
  section
});

const addOrg = details => ({
  type: types.ADD_ORG,
  details
});

const requestSuccess = ({ division, data }) => ({
  type: types.REQUEST_SUCCESS,
  division,
  data
});

const requestFailure = error => ({
  type: types.REQUEST_FAILURE,
  error
});

const reset = ({ division }) => ({
  type: types.RESET,
  division
});

export const actions = {
  requestOrgs,
  requestOrg,
  requestOrgSuccess,
  requestFlights,
  requestFlight,
  requestFlightSuccess,
  requestSections,
  requestSection,
  requestSectionSuccess,
  addOrg,
  requestSuccess,
  requestFailure,
  reset
};

export const initialState = {
  error: {},
  collection: {
    orgs: [],
    flights: [],
    sections: []
  },
  item: {
    org: {},
    flight: {},
    section: {}
  }
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SUCCESS:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.division]: action.data
        }
      };
    case types.REQUEST_ORG_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          org: action.org,
          flight: {},
          section: {}
        },
        collection: {
          ...state.collection,
          sections: []
        }
      };
    case types.REQUEST_FLIGHT_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          flight: action.flight,
          section: {}
        }
      };
    case types.REQUEST_SHOP_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          section: action.section
        }
      };
    case types.REQUEST_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case types.RESET:
      return {
        ...state,
        [action.division]: {}
      };
    default:
      return state;
  }
};
