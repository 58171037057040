import { buildActions } from 'utils';

export const types = buildActions('list', [
  'REQUEST_LIST',
  'REQUEST_BUILDING_LIST',
  'REQUEST_SHIFT_LIST',
  'REQUEST_STATUS_LIST',
  'REQUEST_ZONE_LIST',
  'REQUEST_LISTS',
  'REQUEST_LIST_SUCCESS',
  'REQUEST_LISTS_SUCCESS',
  'RESET_LIST'
]);

const requestList = list => ({
  type: types.REQUEST_LIST,
  list
});

const requestBuildingList = () => ({
  type: types.REQUEST_BUILDING_LIST
});

const requestShiftList = () => ({
  type: types.REQUEST_SHIFT_LIST
});

const requestStatusList = () => ({
  type: types.REQUEST_STATUS_LIST
});

const requestZoneList = () => ({
  type: types.REQUEST_ZONE_LIST
});

const requestLists = () => ({
  type: types.REQUEST_LISTS
});

const requestListSuccess = ({ list, data }) => ({
  type: types.REQUEST_LIST_SUCCESS,
  list,
  data
});

const requestListsSuccess = ({ data }) => ({
  type: types.REQUEST_LISTS_SUCCESS,
  data
});

const resetList = () => ({
  type: types.RESET_LIST
});

export const actions = {
  requestList,
  requestBuildingList,
  requestShiftList,
  requestStatusList,
  requestZoneList,
  requestLists,
  requestListSuccess,
  requestListsSuccess,
  resetList
};

export const initialState = {
  building: [],
  collection: [],
  shift: [],
  status: [],
  zone: []
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_LIST_SUCCESS:
      return {
        ...state,
        [action.list]: action.data
      };
    case types.REQUEST_LISTS_SUCCESS:
      return {
        ...state,
        collection: action.data
      };
    default:
      return state;
  }
};
