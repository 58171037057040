import { buildActions } from 'utils';

export const types = buildActions('event', [
  'REQUEST_EVENTS',
  'REQUEST_DATE_EVENTS',
  'REQUEST_TODAY_EVENTS',
  'REQUEST_TOMORROW_EVENTS',
  'REQUEST_EVENT_TYPES',
  'REQUEST_EVENT_TYPE',
  'ADD_EVENT',
  'DELETE_EVENT',
  'ADD_EVENT_TYPE',
  'DELETE_EVENT_TYPE',
  'REQUEST_SUCCESS',
  'REQUEST_FAILURE',
  'RESET',
  'UI_EVENTS',
]);

const requestEvents = ({ events }) => ({
  type: types.REQUEST_EVENTS,
  events,
});

const requestDateEvents = ({ date }) => ({
  type: types.REQUEST_DATE_EVENTS,
  date,
});

const requestTodayEvents = ({ date }) => ({
  type: types.REQUEST_TODAY_EVENTS,
  date,
});

const requestTomorrowEvents = ({ date }) => ({
  type: types.REQUEST_TOMORROW_EVENTS,
  date,
});

const requestEventTypes = () => ({
  type: types.REQUEST_EVENT_TYPES,
});

const requestEventType = (eventType) => ({
  type: types.REQUEST_EVENT_TYPE,
  eventType,
});

const addEvent = (event) => ({
  type: types.ADD_EVENT,
  event,
});

const deleteEvent = (event) => ({
  type: types.DELETE_EVENT,
  event,
});

const addEventType = (eventType) => ({
  type: types.ADD_EVENT_TYPE,
  eventType,
});

const deleteEventType = (eventType) => ({
  type: types.DELETE_EVENT_TYPES,
  eventType,
});

const requestSuccess = ({ reqType, data }) => ({
  type: types.REQUEST_SUCCESS,
  reqType,
  data,
});

const requestFailure = (error) => ({
  type: types.REQUEST_FAILURE,
  error,
});

const reset = (reqType) => ({
  type: types.RESET,
  reqType,
});

const uiEvents = (ui) => ({
  type: types.UI_EVENTS,
  ui,
});

export const actions = {
  requestEvents,
  requestDateEvents,
  requestTodayEvents,
  requestTomorrowEvents,
  requestEventTypes,
  requestEventType,
  addEvent,
  deleteEvent,
  addEventType,
  deleteEventType,
  requestSuccess,
  requestFailure,
  reset,
  uiEvents,
};

export const initialState = {
  error: {},
  collection: [],
  eventTypes: [],
  eventType: {},
  loaded: false,
  loading: false,
  date: [],
  today: [],
  tomorrow: [],
  uiEvents: {},
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.REQUEST_SUCCESS:
      return {
        ...state,
        [action.reqType]: action.data,
      };
    case types.REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case types.RESET:
      return {
        ...state,
        [action.reqType]: {},
      };
    case types.UI_EVENTS:
      return {
        ...state,
        uiEvents: {
          ...state.uiEvents,
          [action.ui]: !state.uiEvents[action.ui],
        },
      };
    default:
      return state;
  }
};
