import { combineReducers } from 'redux';

import { reducer as auth, initialState as authState } from './auth';
import { reducer as editor, initialState as editorState } from './editor';
import { reducer as event, initialState as eventState } from './event';
import { reducer as forum, initialState as forumState } from './forum';
import { reducer as list, initialState as listState } from './list';
import { reducer as org, initialState as orgState } from './org';
import {
  reducer as personnel,
  initialState as personnelState,
} from './personnel';
import {
  reducer as privilege,
  initialState as privilegeState,
} from './privilege';
import { reducer as role, initialState as roleState } from './role';
import { reducer as toast, initialState as toastState } from './toast';
import { reducer as user, initialState as userState } from './user';

export const initialState = {
  auth: authState,
  editor: editorState,
  event: eventState,
  forum: forumState,
  list: listState,
  org: orgState,
  personnel: personnelState,
  privilege: privilegeState,
  role: roleState,
  toast: toastState,
  user: userState,
};

export default combineReducers({
  auth,
  editor,
  event,
  forum,
  list,
  org,
  personnel,
  privilege,
  role,
  toast,
  user,
});
