import { lazy } from 'react';

export const imports = {
  AdminForum: () =>
    import(/* webpackChunkName: "admin-forum" */ 'pages/Admin/Forum'),
  Events: () => import(/* webpackChunkName: "events" */ 'pages/Events'),
  Forum: () => import(/* webpackChunkName: "forum" */ 'pages/Forum'),
  ForumTopics: () =>
    import(/* webpackChunkName: "forum-topics" */ 'pages/ForumTopics'),
  ForumTopic: () =>
    import(/* webpackChunkName: "forum-topic" */ 'pages/ForumTopic'),
  Home: () => import(/* webpackChunkName: "home" */ 'pages/Home'),
  Login: () => import(/* webpackChunkName: "login" */ 'pages/Login'),
  NotFound: () => import(/* webpackChunkName: "not-found" */ 'pages/NotFound'),
  Organization: () =>
    import(/* webpackChunkName: "org" */ 'pages/Organization'),
  Personnel: () =>
    import(/* webpackChunkName: "personnel" */ 'pages/Personnel'),
  Privileges: () =>
    import(/* webpackChunkName: "privileges" */ 'pages/Privileges'),
  Recall: () => import(/* webpackChunkName: "recall" */ 'pages/Recall'),
  Register: () => import(/* webpackChunkName: "register" */ 'pages/Register'),
  Roles: () => import(/* webpackChunkName: "roles" */ 'pages/Roles'),
  Users: () => import(/* webpackChunkName: "users" */ 'pages/Users'),
};

export const AdminForum = lazy(imports.AdminForum);
export const Events = lazy(imports.Events);
export const Forum = lazy(imports.Forum);
export const ForumTopics = lazy(imports.ForumTopics);
export const ForumTopic = lazy(imports.ForumTopic);
export const Home = lazy(imports.Home);
export const Login = lazy(imports.Login);
export const NotFound = lazy(imports.NotFound);
export const Organization = lazy(imports.Organization);
export const Personnel = lazy(imports.Personnel);
export const Privileges = lazy(imports.Privileges);
export const Recall = lazy(imports.Recall);
export const Register = lazy(imports.Register);
export const Roles = lazy(imports.Roles);
export const Users = lazy(imports.Users);
