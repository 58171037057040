import { all, call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import helper from 'utils/saga';
import { actions, types } from 'redux/reducers/org';

// snake_cased variables here come from RFC 6749
function* requestOrgsWorker({ level }) {
  try {
    const endpoint = {
      url: `/colony/orgs/${level}`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestSuccess({ division: 'orgs', data }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get organizations!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestFlightsWorker({ org }) {
  try {
    const endpoint = {
      url: `/colony/org/${org.key}/flights`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestSuccess({ division: 'flights', data }));
      yield put(actions.requestOrgSuccess({ org }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get flights!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestSectionsWorker({ org, flight }) {
  try {
    const endpoint = {
      url: `/colony/org/${org.key}/${flight.key}/sections`,
      method: 'GET'
    };
    const result = yield call(request.execute, { endpoint });

    // update user in state or throw an error
    if (result.success) {
      const {
        response: { data }
      } = result;

      yield put(actions.requestSuccess({ division: 'sections', data }));
      yield put(actions.requestFlightSuccess({ flight }));
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error('Failed to get sections!');
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

// snake_cased variables here come from RFC 6749
function* requestSectionWorker({ org, flight, section }) {
  try {
    const endpoint = {
      url: `/colony/org/${org.key}/${flight.key}/${section.key}`,
      method: 'GET'
    };
    if (!section) {
      const result = yield call(request.execute, { endpoint });

      // update user in state or throw an error
      if (result?.success) {
        const {
          response: { section }
        } = result;
        yield put(actions.requestSectionSuccess({ section }));
      } else if (result.error) {
        throw result.error;
      } else {
        throw new Error('Failed to get sections!');
      }
    } else {
      yield put(actions.requestSectionSuccess({ section }));
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* addOrgWorker({ details: { key, org, parent, name, type, update } }) {
  try {
    const endpoint = {
      url: `/colony/org`,
      method: !update ? 'POST' : 'PUT'
    };

    const data = { _key: key.toLowerCase(), org, parent, type, name };

    const result = yield call(request.execute, { endpoint, data });

    if (result.success) {
      if (type === 'flight') {
        yield put(actions.requestFlights({ key: parent }));
      }
      if (type === 'section') {
        yield put(actions.requestSections({ key: org }, { key: parent }));
      }
      yield call(helper.toast, {
        title: !update ? 'Organization Created' : `Organization Updated`,
        message: !update
          ? `${name} successfully added!`
          : `${name} successfully updated!`
      });
    } else if (result.error) {
      throw result.error;
    } else {
      throw new Error(`Failed to add ${name}!`);
    }
  } catch (error) {
    const { message } = error;

    yield put(actions.requestFailure(error));
    yield call(helper.errorToast, message);
  }
}

function* requestOrgsWatcher() {
  yield takeLatest(types.REQUEST_ORGS, requestOrgsWorker);
}

function* requestFlightsWatcher() {
  yield takeLatest(types.REQUEST_FLIGHTS, requestFlightsWorker);
}

function* requestSectionsWatcher() {
  yield takeLatest(types.REQUEST_SHOPS, requestSectionsWorker);
}

function* requestSectionWatcher() {
  yield takeLatest(types.REQUEST_SHOP, requestSectionWorker);
}

function* addOrgWatcher() {
  yield takeLatest(types.ADD_ORG, addOrgWorker);
}

export const workers = {
  requestOrgsWorker,
  requestFlightsWorker,
  requestSectionsWorker,
  requestSectionWorker,
  addOrgWorker
};

export const watchers = {
  requestOrgsWatcher,
  requestFlightsWatcher,
  requestSectionsWatcher,
  requestSectionWatcher,
  addOrgWatcher
};

export default function* saga() {
  yield all(Object.values(watchers).map(watcher => watcher()));
}
